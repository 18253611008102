import { fetchRequestWithTypes } from './index.js'; // GET a user.

// GET a user.

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERRORED = 'GET_USER_ERRORED';

export const getUser = () =>
	fetchRequestWithTypes({
		types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_ERRORED],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid',
		},
	});

export const getUserByUUID = () =>
	fetchRequestWithTypes({
		types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_ERRORED],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid',
		},
	});

// POST a user.

export const POST_USER_REQUEST = 'POST_USER_REQUEST';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_ERRORED = 'POST_USER_ERRORED';

export const postUser = (body) =>
	fetchRequestWithTypes({
		types: [POST_USER_REQUEST, POST_USER_SUCCESS, POST_USER_ERRORED],
		request: {
			method: 'POST',
			path: '/apis/api/v1/users',
			body: {
				...body,
				no_account: true,
			},
		},
	});

// PATCH a user.

export const PATCH_USER_REQUEST = 'PATCH_USER_REQUEST';
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
export const PATCH_USER_ERRORED = 'PATCH_USER_ERRORED';

export const patchUser = (body) =>
	fetchRequestWithTypes({
		types: [PATCH_USER_REQUEST, PATCH_USER_SUCCESS, PATCH_USER_ERRORED],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/:uuid',
			body,
		},
	});

// PATCH a reset password with a token (emailed to the user) and a new password.

export const PATCH_RESET_PASSWORD_REQUEST = 'PATCH_RESET_PASSWORD_REQUEST';
export const PATCH_RESET_PASSWORD_SUCCESS = 'PATCH_RESET_PASSWORD_SUCCESS';
export const PATCH_RESET_PASSWORD_ERRORED = 'PATCH_RESET_PASSWORD_ERRORED';

export const patchResetPassword = (body) =>
	fetchRequestWithTypes({
		types: [
			PATCH_RESET_PASSWORD_REQUEST,
			PATCH_RESET_PASSWORD_SUCCESS,
			PATCH_RESET_PASSWORD_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/reset_password_with_token',
			body,
		},
	});

// Updates a user in the closed state
// https://edge.stsh.io/documentation/1.0/users/update_on_reopen.html
export const PATCH_USER_REOPEN_REQUEST = 'PATCH_USER_REOPEN_REQUEST';
export const PATCH_USER_REOPEN_SUCCESS = 'PATCH_USER_REOPEN_SUCCESS';
export const PATCH_USER_REOPEN_ERRORED = 'PATCH_USER_REOPEN_ERRORED';

export const patchUserReopen = (user) =>
	fetchRequestWithTypes({
		types: [
			PATCH_USER_REOPEN_REQUEST,
			PATCH_USER_REOPEN_SUCCESS,
			PATCH_USER_REOPEN_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/:uuid/reopen',
			body: { user },
		},
	});

// Allows user to redeem referral code.
// https://staging.stsh.io/documentation/1.0/referrals/apply_promo_code.html

export const POST_PROMO_REQUEST = 'POST_PROMO_REQUEST';
export const POST_PROMO_SUCCESS = 'POST_PROMO_SUCCESS';
export const POST_PROMO_ERRORED = 'POST_PROMO_ERRORED';

export const postUserPromo = (code) =>
	fetchRequestWithTypes({
		types: [POST_PROMO_REQUEST, POST_PROMO_SUCCESS, POST_PROMO_ERRORED],
		request: {
			method: 'POST',
			path: '/apis/promotions/api/v1/users/:uuid/applyPromotionCode',
			body: {
				code,
			},
		},
	});

// Updates a user's password.
// https://edge.stsh.io/documentation/1.0/users/update_password.html

export const PUT_PASSWORD_REQUEST = 'PUT_PASSWORD_REQUEST';
export const PUT_PASSWORD_SUCCESS = 'PUT_PASSWORD_SUCCESS';
export const PUT_PASSWORD_ERRORED = 'PUT_PASSWORD_ERRORED';

export const putPassword = (old_password, password) =>
	fetchRequestWithTypes({
		types: [PUT_PASSWORD_REQUEST, PUT_PASSWORD_SUCCESS, PUT_PASSWORD_ERRORED],
		request: {
			method: 'PUT',
			path: '/apis/api/v1/users/:uuid/password',
			body: {
				old_password,
				password,
			},
		},
	});

export const SET_USER_IRA_ELIGIBILITY = 'SET_USER_IRA_ELIGIBILITY';
export const setUserIRAEligibility = (ira_eligible) => ({
	type: SET_USER_IRA_ELIGIBILITY,
	ira_eligible,
});
