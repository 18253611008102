export const ACCESS_TOKEN_COOKIE_NAME = 'stashAccessToken';
export const USER_ID_COOKIE_NAME = 'stashUserID';
export const UUID_COOKIE_NAME = 'stashUUID';
export const DEVICE_ID_COOKIE_NAME = 'stashDeviceID';

export const REFRESH_TOKEN_COOKIE_NAME = 'stashRefreshToken';

export const SESSION_TIMEOUT_INTERVAL =
	window.Stash.env === 'production' ? 1000 * 60 * 20 : 9999999999;

//never allow access token to expire by refreshing it every 4 minutes = 240000 ms
export const TOKEN_REFRESH_INTERVAL =
	process.env.STASH_ENV === 'local' ? 3156000000000 : 240000;

export const IDV_FLOW_NEXT_STEP = 'idvFlowNextStep';
export const IDV_FLOW_PARENT = 'idvFlowParent';
export const IDV_RETURN = 'idvReturn';
