/**
 * This middleware fires events to Braze
 */

// import { fireBrazeEvent } from 'stash/actions/braze';
import {
	AUTH_SESSION_START,
	FLUSH_SIGN_UP,
	GET_CARD_DETAIL_SUCCESS,
	GET_LAUNCH_INFO_SUCCESS,
	GET_USER_SUCCESS,
	LOG_OUT,
	POST_USER_SUCCESS,
} from 'stash/actions';

import { tryWarn } from 'stash/utils';
import {
	automaticallyShowInAppMessages,
	changeUser,
	destroy,
	initialize,
	openSession,
	removeAllSubscriptions,
} from '@braze/web-sdk';
import { BRAZE_ENV_APP_KEY, BRAZE_SDK_ENDPOINT } from 'stash/constants';

// No easy way to track this in the Braze SDK so doing it here.
let sessionIsOpen = false;

const initializeBraze = tryWarn(() => {
	if (process.env.NODE_ENV === 'test') return;

	initialize(BRAZE_ENV_APP_KEY, {
		baseUrl: BRAZE_SDK_ENDPOINT,
		enableLogging: /-/.test(window.location.hostname),
		allowUserSuppliedJavascript: true,
	});
});

const destroyBrazeInstance = tryWarn(() => {
	sessionIsOpen = false;
	removeAllSubscriptions();
	destroy();
});
//
const setBrazeUser = tryWarn((...args) => {
	if (sessionIsOpen) return;
	sessionIsOpen = true;
	automaticallyShowInAppMessages();
	changeUser(...args);
	openSession();
});

export const logBrazeEvent = tryWarn((...args) => {
	// return logCustomEvent(...args);
});

/**
 * This function will attempt to send any pending events to Braze immediately.
 * Otherwise, Braze only sends events every ~10 seconds.
 * @link https://js.appboycdn.com/web-sdk/latest/doc/modules/braze.html#requestimmediatedataflush
 * This fixes an edge case where the user logs out before the events are sent.
 * Some flows using Braze, such as Project Save churn promo, rely on events reliably sent to Braze.
 */
export const immediatelyFlushBrazeEvents = tryWarn(() => {
	// requestImmediateDataFlush((success) => {
	// 	if (!success) {
	// 		console.error(
	// 			'Braze failed to immediately flush events. Pending braze events will be flushed during the next successful flush.'
	// 		);
	// 	}
	// });
});

const events = {
	[FLUSH_SIGN_UP]: (action) => {
		logBrazeEvent('home_screen_post_reg', {});
	},

	[GET_CARD_DETAIL_SUCCESS]: (action) => {
		logBrazeEvent('screen_view', {
			screen_name: 'investment',
			investment_identifier: action?.response?.card?.card_uuid,
		});
	},
};

const brazeReducer =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		switch (action.type) {
			case AUTH_SESSION_START:
				initializeBraze();
				break;
			case LOG_OUT:
				destroyBrazeInstance();
				break;
			case GET_USER_SUCCESS:
			case POST_USER_SUCCESS:
			case GET_LAUNCH_INFO_SUCCESS:
				setBrazeUser(action.response.user.braze_id);
				break;
			default:
				break;
		}
		if (typeof events[action.type] === 'function') {
			// dispatch(fireBrazeEvent(action.type));
			// events[action.type](action);
		}

		return next(action);
	};

export default brazeReducer;
