import { fetchRequestWithTypes } from './index.js';

export const GET_ELIGIBLE_PLATFORM_TIERS_REQUEST = 'GET_ELIGIBLE_PLATFORM_TIERS_REQUEST';
export const GET_ELIGIBLE_PLATFORM_TIERS_SUCCESS = 'GET_ELIGIBLE_PLATFORM_TIERS_SUCCESS';
export const GET_ELIGIBLE_PLATFORM_TIERS_ERRORED = 'GET_ELIGIBLE_PLATFORM_TIERS_ERRORED';

export const getEligiblePlatformTiers = (funnelType) => {
	const funnelTypeQueryString =
		funnelType === 'retire' ? `?funnel_type=${funnelType}` : '';

	return fetchRequestWithTypes({
		types: [
			GET_ELIGIBLE_PLATFORM_TIERS_REQUEST,
			GET_ELIGIBLE_PLATFORM_TIERS_SUCCESS,
			GET_ELIGIBLE_PLATFORM_TIERS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/platform_tiers/eligible_platform_tiers${funnelTypeQueryString}`,
		},
	});
};

export const GET_ACTIVE_PLATFORM_TIER_REQUEST = 'GET_ACTIVE_PLATFORM_TIER_REQUEST';
export const GET_ACTIVE_PLATFORM_TIER_SUCCESS = 'GET_ACTIVE_PLATFORM_TIER_SUCCESS';
export const GET_ACTIVE_PLATFORM_TIER_ERRORED = 'GET_ACTIVE_PLATFORM_TIER_ERRORED';

export const getActivePlatformTier = () =>
	fetchRequestWithTypes({
		types: [
			GET_ACTIVE_PLATFORM_TIER_REQUEST,
			GET_ACTIVE_PLATFORM_TIER_SUCCESS,
			GET_ACTIVE_PLATFORM_TIER_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/platform_tiers/active_platform_tier`,
		},
	});

// Retrieves changeable platform tiers for user based on user's active platform tier
export const GET_CHANGEABLE_PLATFORM_TIERS_REQUEST =
	'GET_CHANGEABLE_PLATFORM_TIERS_REQUEST';
export const GET_CHANGEABLE_PLATFORM_TIERS_SUCCESS =
	'GET_CHANGEABLE_PLATFORM_TIERS_SUCCESS';
export const GET_CHANGEABLE_PLATFORM_TIERS_ERRORED =
	'GET_CHANGEABLE_PLATFORM_TIERS_ERRORED';

export const getChangeablePlatformTiers = () =>
	fetchRequestWithTypes({
		types: [
			GET_CHANGEABLE_PLATFORM_TIERS_REQUEST,
			GET_CHANGEABLE_PLATFORM_TIERS_SUCCESS,
			GET_CHANGEABLE_PLATFORM_TIERS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/platform_tiers/manage`,
		},
	});

// Returns instructions to downgrade or cancel plan
// https://edge.stsh.io/documentation/1.0/platform_tiers/downgrade_steps.html

export const GET_PLATFORM_TIER_CHANGE_STEPS_REQUEST =
	'GET_PLATFORM_TIER_CHANGE_STEPS_REQUEST';
export const GET_PLATFORM_TIER_CHANGE_STEPS_SUCCESS =
	'GET_PLATFORM_TIER_CHANGE_STEPS_SUCCESS';
export const GET_PLATFORM_TIER_CHANGE_STEPS_ERRORED =
	'GET_PLATFORM_TIER_CHANGE_STEPS_ERRORED';

export const getPlatformTierChangeSteps = (tier) =>
	fetchRequestWithTypes({
		types: [
			GET_PLATFORM_TIER_CHANGE_STEPS_REQUEST,
			GET_PLATFORM_TIER_CHANGE_STEPS_SUCCESS,
			GET_PLATFORM_TIER_CHANGE_STEPS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/platform_tiers/change_platform_tier_steps?tier_type=${tier}`,
		},
	});

export const POST_USER_PLATFORM_TIER_REQUEST = 'POST_USER_PLATFORM_TIER_REQUEST';
export const POST_USER_PLATFORM_TIER_SUCCESS = 'POST_USER_PLATFORM_TIER_SUCCESS';
export const POST_USER_PLATFORM_TIER_ERRORED = 'POST_USER_PLATFORM_TIER_ERRORED';

export const postUserPlatformTier = (tier_type) => {
	return fetchRequestWithTypes({
		types: [
			POST_USER_PLATFORM_TIER_REQUEST,
			POST_USER_PLATFORM_TIER_SUCCESS,
			POST_USER_PLATFORM_TIER_ERRORED,
		],
		request: {
			method: 'POST',
			path: '/apis/api/v1/users/:uuid/platform_tiers/user_platform_tiers',
			body: { tier_type },
		},
	});
};

// Changes user's tier and subscriptions
// https://staging.stsh.io/documentation/1.0/platform_tiers/update.html

export const POST_USER_PLATFORM_TIER_UPDATE_REQUEST =
	'POST_USER_PLATFORM_TIER_UPDATE_REQUEST';
export const POST_USER_PLATFORM_TIER_UPDATE_SUCCESS =
	'POST_USER_PLATFORM_TIER_UPDATE_SUCCESS';
export const POST_USER_PLATFORM_TIER_UPDATE_ERRORED =
	'POST_USER_PLATFORM_TIER_UPDATE_ERRORED';

export const postUserPlatformTierUpdate = (tier_type) => {
	return fetchRequestWithTypes({
		types: [
			POST_USER_PLATFORM_TIER_UPDATE_REQUEST,
			POST_USER_PLATFORM_TIER_UPDATE_SUCCESS,
			POST_USER_PLATFORM_TIER_UPDATE_ERRORED,
		],
		request: {
			method: 'POST',
			path: '/apis/concierge/api/v1/users/:uuid/platform_tiers/update',
			body: { tier_type },
		},
	});
};

// Initiate canceling the tier of user and closing accounts if applicable
// https://edge.stsh.io/documentation/1.0/platform_tiers/cancel.html

export const PATCH_PLATFORM_TIER_CANCEL_REQUEST = 'PATCH_PLATFORM_TIER_CANCEL_REQUEST';
export const PATCH_PLATFORM_TIER_CANCEL_SUCCESS = 'PATCH_PLATFORM_TIER_CANCEL_SUCCESS';
export const PATCH_PLATFORM_TIER_CANCEL_ERRORED = 'PATCH_PLATFORM_TIER_CANCEL_ERRORED';

export const patchPlatformTierCancel = () => {
	return fetchRequestWithTypes({
		types: [
			PATCH_PLATFORM_TIER_CANCEL_REQUEST,
			PATCH_PLATFORM_TIER_CANCEL_SUCCESS,
			PATCH_PLATFORM_TIER_CANCEL_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/concierge/api/v1/users/:uuid/platform_tiers/cancel',
		},
	});
};

export const GET_MONTHLY_MARKET_REPORTS_REQUEST = 'GET_MONTHLY_MARKET_REPORTS_REQUEST';
export const GET_MONTHLY_MARKET_REPORTS_SUCCESS = 'GET_MONTHLY_MARKET_REPORTS_SUCCESS';
export const GET_MONTHLY_MARKET_REPORTS_ERRORED = 'GET_MONTHLY_MARKET_REPORTS_ERRORED';

export const getMonthlyMarketReports = () =>
	fetchRequestWithTypes({
		types: [
			GET_MONTHLY_MARKET_REPORTS_REQUEST,
			GET_MONTHLY_MARKET_REPORTS_SUCCESS,
			GET_MONTHLY_MARKET_REPORTS_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid/platform_tiers/monthly_market_reports',
		},
	});

export const GET_BILLING_SUMMARY_REQUEST = 'GET_BILLING_SUMMARY_REQUEST';
export const GET_BILLING_SUMMARY_SUCCESS = 'GET_BILLING_SUMMARY_SUCCESS';
export const GET_BILLING_SUMMARY_ERRORED = 'GET_BILLING_SUMMARY_ERRORED';

export const getBillingSummary = () => {
	return fetchRequestWithTypes({
		types: [
			GET_BILLING_SUMMARY_REQUEST,
			GET_BILLING_SUMMARY_SUCCESS,
			GET_BILLING_SUMMARY_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/customers/v1/users/:uuid/subscriptions/invoicePreview',
		},
	});
};

export const GET_SUBSCRIPTION_ACTION_REQUEST = 'GET_SUBSCRIPTION_ACTION_REQUEST';
export const GET_SUBSCRIPTION_ACTION_SUCCESS = 'GET_SUBSCRIPTION_ACTION_SUCCESS';
export const GET_SUBSCRIPTION_ACTION_ERRORED = 'GET_SUBSCRIPTION_ACTION_ERRORED';

export const getSubscriptionActions = (action, state) => {
	return fetchRequestWithTypes({
		types: [
			GET_SUBSCRIPTION_ACTION_REQUEST,
			GET_SUBSCRIPTION_ACTION_SUCCESS,
			GET_SUBSCRIPTION_ACTION_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/subscriptionActions?subscriptionAction=${action}&state=${state}`,
		},
	});
};
